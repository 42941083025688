!<template>
  <div class="signPage">
    <div class="loginModel">
      <div class="title tc">World Freight Alliance</div>
      <div class="avaterImg">
        <div class="markImg tc">
          <fileUpload
            @uploadCompleteOSS="uploadimg"
            :needCropImg="fileUploadOptions.needCropImg"
            :aspectRatio="fileUploadOptions.aspectRatio"
            :accept="fileUploadOptions.accept"
          >
            <span>{{ $t("changeAvatar") }}</span>
          </fileUpload>
        </div>
        <al-image
          class="avatar"
          :src="formData.avatar ? formData.avatar : DEFAULT_AVATARSQUARE"
        ></al-image>
      </div>
      <div class="inputPage">
        <el-form ref="form" :model="formData" :rules="rules">
          <el-form-item prop="nick_name">
            <el-input
              v-model="formData.nick_name"
              :placeholder="$t('Pleasenickname')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" style="height: 60px">
            <el-input
              type="password"
              v-model="formData.password"
              :placeholder="$t('enterPassword')"
              @keyup.enter.native="login"
            ></el-input>
            <div
              class="custom_error_tip"
              v-show="phoneErrorShow && formData.password"
            >
              {{ $t("invalidPassword") }}
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="signBtn" @click="login">{{
              $t("emailAladyTip2")
            }}</el-button>
          </el-form-item>
        </el-form>
        <div class="tc recommend">{{ $t("browser58") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";

export default {
  components: { fileUpload },

  data() {
    return {
      formData: {
        nick_name: "",
        password: "",
        avatar: "",
      },
      signInfo: {},
      phoneErrorShow: false,
      fileUploadOptions: {
        needCropImg: true,
        aspectRatio: 20 / 20,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      rules: {
        nick_name: [
          {
            required: true,
            message: this.$t("Pleasenickname"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("enterPassword"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "formData.password"() {
      if (!this.formData.password) {
        this.phoneErrorShow = false;
      }
    },
  },
  mounted() {
    this.signInfo = this._decode(this.$route.query.parameter);
    // this.formData.nick_name = this.signInfo.nick_name;
    this.getNickName();
  },
  methods: {
    login() {
      this.$refs.form.validate((valid) => {
        this.sign();
      });
    },
    async getNickName() {
      try {
        let result = await this.$store.dispatch("baseStore/getChatNickname", {
          invite_id: this.signInfo.invite_id,
        });
        if (result.success) {
          this.formData.nick_name = result.data.nickname;
          this.formData.avatar = result.data.avatar;
        }
      } catch (e) {}
    },
    async sign() {
      this.phoneErrorShow = false;
      let params = {
        invite_id: this.signInfo.invite_id,
      };
      let _params = Object.assign(params, this.formData);
      let result = await this.$store.dispatch(
        "baseStore/multChatLogin",
        _params
      );
      if (result.success) {
        this.$router.push({
          path: "/multiChat",
          query: {
            parameter: this._encode({
              channel_id: this.signInfo.channel_id + "",
              user_id: this.signInfo.user_id,
            }),
          },
        });
      } else {
        this.phoneErrorShow = true;
      }
    },
    uploadimg(result) {
      this.formData.avatar = result.url;
    },
  },
};
</script>
<style lang="less" scoped>
.signPage {
  width: 100%;
  height: calc(100vh);
  background: url("../../assets/backSign.jpg");
  background-size: 100%;
  position: relative;

  .loginModel {
    width: 340px;
    // height: 350px;
    background: #000000b3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 0 30px;
    div {
      color: #fff;
    }
    .title {
      font-size: 16px;
      padding-bottom: 20px;
    }
    .inputPage {
      width: 240px;
      margin: 0 auto;
      /deep/.el-input__inner {
        text-align: center;
        background: transparent;
        border: none;
        border-bottom: 1px solid #cecece;
        border-radius: 0;
        color: #aeabab;
      }
      /deep/.el-input__inner:focus {
        border-bottom: 1px solid #fff;
        color: #fff;
      }
      /deep/.el-input input::-webkit-input-placeholder {
        color: #818d9f;
      }
      /deep/.el-input input::-moz-input-placeholder {
        color: #818d9f;
      }
      /deep/.el-input input::-ms-input-placeholder {
        color: #818d9f;
      }

      .signBtn {
        width: 100%;
        // margin-top: 12px;
      }
      .recommend {
        color: #aeabab;
        margin-top: 28px;
      }
    }
  }
}
.avaterImg {
  position: relative;
  width: 70px;
  height: 70px;
  margin: 12px auto 12px;
  border-radius: 50%;
  .markImg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    line-height: 70px;
    transition: 0.5s;
    span {
      color: #fff;
      display: none;
    }
  }
  &:hover {
    .markImg {
      background-color: rgba(0, 0, 0, 0.5);
      span {
        display: block;
      }
    }
  }
}
.custom_error_tip {
  color: #ff5155 !important;
  font-size: 12px;
  line-height: 20px;
}
</style>